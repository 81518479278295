body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*[role='button']:hover {
  cursor: pointer
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

#summitwealth {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

canvas {
  width: 100% !important;
}

.rcs-custom-scroll .rcs-inner-handle {
  background: rgba(0,0,0,.2);
}

.percentHeader, .percentValue {
  margin: 12px 36px;
  font-weight: 700;
}

.inRange {
  color: green;
}

.middleLine {
  background: #2b2b2b;
  width: 100%;
  height: 1px;
}

.tickMark {
  background: #2b2b2b;
  width: 1px;
  height: 4px;
  margin: 0 auto;
}

.disabledText { 
  color: #c9ccda;
}

.allocationArrow {
  margin-top: -8px;
}

.section-title {
  text-transform: uppercase;
  font-weight: bolder !important;
  font-size: 16;
  color: #212945
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  cursor: pointer;
  color: #414955 !important;
  text-decoration: underline;
}

a:visited {
  color: inherit;
}

a:active {
  color: inherit;
  text-decoration: underline;
}

.clickable {
  cursor: pointer
}

.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px) !important;
}

.big_tooltip {
  font-size: 12px !important;
  font-weight: 600 !important;
}
