@font-face {
  font-family: Gotham-Book;
  src: url(./Gotham-Book.woff);
}

@font-face {
  font-family: GothamPro;
  src: url(./GothamPro.woff);
}

@font-face {
  font-family: GothamPro-Bold;
  src: url(./GothamPro-Bold.woff);
}

@font-face {
  font-family: GothamPro-Black;
  src: url(./GothamPro-Black.woff);
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-Regular.woff);
}

@font-face {
  font-family: 'Lato Italic';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-Italic.woff);
}

@font-face {
  font-family: 'Lato Hairline';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-Hairline.woff);
}

@font-face {
  font-family: 'Lato Hairline Italic';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-HairlineItalic.woff);
}

@font-face {
  font-family: 'Lato Light';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-Light.woff);
}

@font-face {
  font-family: 'Lato Light Italic';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-LightItalic.woff);
}

@font-face {
  font-family: 'Lato Bold';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-Bold.woff);
}

@font-face {
  font-family: 'Lato Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-BoldItalic.woff);
}

@font-face {
  font-family: 'Lato Black';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-Black.woff);
}

@font-face {
  font-family: 'Lato Black Italic';
  font-style: normal;
  font-weight: normal;
  src: url(./Lato-BlackItalic.woff);
}