.mentions {
    margin: 1em 0;
    font-size: 14px;
  }
  
  .mentions--singleLine .mentions__control {
    display: inline-block;
    width: 130px;
  }
  .mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
  }
  .mentions--singleLine .mentions__input {
    padding: 1px;
    border: 2px inset;
  }
  
  .mentions--multiLine .mentions__control {
    font-size: inherit;
  }
  .mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 0px;
  }
  .mentions--multiLine .mentions__input {
    border: 1px solid transparent;
    padding: 0px;
    outline: 0;
    line-height: 20px;
  }
  
  .mentions__suggestions__list {
    font-size: inherit;
    inset: auto;
    z-index: 1301;
    position: fixed;
    max-height: 15rem;
    overflow-y: auto;
    transition: all 1s ease 0s;
    border-radius: 0.5rem;
    background-color: rgb(255, 255, 255);
  }
  
  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  .mentions__suggestions__item--focused {
    background-color: #c7caca;
  }
  
  .mentions__mention {
    position: relative;
    z-index: 1;
    color: #007bff;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
      -1px -1px 1px white;
    text-decoration: none;
    pointer-events: none;
  }